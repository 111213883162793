import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";

const app = createApp(App);

app.use(Quasar, {
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
  config: {
    loading: {},
  },
  quasarUserOptions,
});

// provide
app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

app.provide("rupiah", (number) => {
  return Intl.NumberFormat("id", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 2,
  }).format(number);
});

app.provide("thousand", (number) => {
  return Intl.NumberFormat("id").format(number);
});

app.directive("can", {
  created(el, binding, vnode) {
    // vuex diisi saat sukses login
    let role = binding.instance.route?.meta.user;
    if (binding.value.includes("PURCHASING") && role?.is_purchasing == "1") {
      return;
    }
    if (binding.value.includes("RND") && role?.is_finance == "1") {
      return;
    }
    // // ADMINISTRATOR
    // else if (role.department == "ADMINISTRATOR") {
    //   return;
    // }
    // ELSE
    else {
      el.style.display = "none";
    }
  },
});

app.use(store).use(router);

app.mount("#app");
