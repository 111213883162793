<template>
  <q-layout view="lHh Lpr lFf">
    <q-header v-if="!route.meta.noAuth" elevated class="text-black bg-white">
      <q-toolbar v-if="route.meta.user">
        <q-btn
          dense
          flat
          round
          icon="menu"
          @click="leftDrawerOpen = !leftDrawerOpen"
        />

        <q-toolbar-title> Purchasing App </q-toolbar-title>
        <q-btn-dropdown
          dense
          flat
          color="primary"
          class="text-bold"
          :label="route.meta.user.name"
        >
          <div class="row no-wrap q-pa-md items-center">
            <div class="column q-gutter-y-sm">
              <div class="text-h6">Settings</div>

              <!-- <q-btn
                dense
                unelevated
                color="primary"
                label="Change Password"
                no-caps
                class="q-px-sm text-bold"
              ></q-btn> -->
              <q-btn
                dense
                class="q-px-sm text-bold"
                label="Logout"
                color="negative"
                no-caps
                @click="logout"
              ></q-btn>
            </div>

            <q-separator vertical inset class="q-mx-lg" />

            <div class="column items-center">
              <q-avatar
                color="primary"
                size="72px"
                class="text-white text-bold"
              >
                {{ route.meta.user.username[0].toUpperCase() }}
              </q-avatar>

              <div class="text-subtitle1 q-mt-md q-mb-xs">
                {{ route.meta.user.username }}
              </div>
            </div>
          </div>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="!route.meta.noAuth"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2 column justify-between"
    >
      <q-list>
        <q-expansion-item
          expand-separator
          icon="drafts"
          label="Pesanan"
          to="/pesanan"
          v-if="
            route.meta.user?.is_purchasing == '0' &&
            route.meta.user?.is_finance == '0'
          "
        >
          <q-item :active="route.name == 'List-Pesanan'" to="/pesanan"
            >List Pengajuan</q-item
          >
          <q-item :active="route.name == 'Form-Pesanan'" to="/pesanan/form"
            >Form Pengajuan</q-item
          >
        </q-expansion-item>
        <q-item
          to="/purchase-approval"
          clickable
          v-ripple
          v-if="route.meta.user?.is_purchasing == '1'"
        >
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>Purchasing Approval</q-item-section>
        </q-item>
        <q-item
          to="/finance-approval"
          clickable
          v-ripple
          v-if="route.meta.user?.is_finance == '1'"
        >
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>Finance Approval</q-item-section>
        </q-item>
        <q-item
          to="/po"
          clickable
          v-ripple
          v-if="route.meta.user?.is_purchasing == '1'"
        >
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>PO</q-item-section>
        </q-item>
        <!-- <q-item clickable v-ripple v-if="route.meta.user?.is_purchasing == '1'">
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>History Pembelian</q-item-section>
        </q-item> -->
      </q-list>
      <!-- <q-list>
        <q-item @click="logout" clickable v-ripple>
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>Logout</q-item-section>
        </q-item>
        <q-item @click="logout" clickable v-ripple>
          <q-item-section avatar>
            <q-icon name="drafts" />
          </q-item-section>

          <q-item-section>Logout</q-item-section>
        </q-item>
      </q-list> -->
    </q-drawer>

    <q-page-container class="my-font" style="background-color: #f4f4f4">
      <router-view class="my-font" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import useBasic from "./composables/useBasic";
export default {
  name: "LayoutDefault",

  components: {
    HelloWorld,
  },
  // TODO notes belum ditampilkan
  // TODO kategori blm dipilih
  setup() {
    const { route, router, onMounted, reactive } = useBasic();
    onMounted(() => {});

    let logout = () => {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      router.push("/login");
    };

    return {
      leftDrawerOpen: ref(false),
      route,
      logout,
    };
  },
};
</script>
<style lang="scss">
@import "@/styles/quasar.scss";
.stickyTable {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: $blue-10;
    color: white;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
    border: 0.1px solid grey;
  }
  thead tr:first-child th {
    top: 0;
  }
  &.q-table--loading thead tr:last-child th {
    top: 48px;
  }
}
.l-container {
  height: 100vh;
}
.l-mobile-container {
  max-width: 1280px;
  min-width: 300px;
  height: calc(100vh - 50px);

  margin: 0 auto;
}
.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.l-grow {
  flex-grow: 99;
}

@font-face {
  font-family: customfont;
  src: url(./styles/fonts/Sora-Regular.ttf);
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Sora-SemiBold.ttf");
  font-weight: 500;
}

.my-font {
  font-family: "customfont";
}

.f10 {
  font-size: 10pt;
}
.f12 {
  font-size: 12pt;
}
.f14 {
  font-size: 14pt;
}
.f16 {
  font-size: 16pt;
}
.f18 {
  font-size: 18pt;
}
.f20 {
  font-size: 20pt;
}

.text-bolder {
  font-weight: 900;
}

.bg-brand {
  background: #3d3099 !important;
}

.text-brand {
  color: #3d3099 !important;
}

.l-text-header {
  font-size: 40px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title {
  font-size: 32px !important;
  line-height: 1.8;
  @media only screen and (max-width: 900px) {
    font-size: 26px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px !important;
  }
}

.l-text-subtitle {
  font-size: 20px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 16px !important;
  }
}

.l-text-detail {
  font-size: 18px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.l-text-description {
  font-size: 14px !important;
  line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 11px !important;
  }
}

.border-card {
  border-radius: 10px !important;
}

.long-text-dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.radius-left {
  border: 1px solid #bbbbbb;
  border-radius: 8px 0 0 8px !important;
}

.radius-right {
  border: 1px solid #bbbbbb;
  border-radius: 0 8px 8px 0 !important;
}

.just-border {
  border: 1px solid #bbbbbb !important;
}
</style>
