import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/Dashboard.vue";
import axios from "axios";
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: HomeView,
  },
  {
    path: "/pesanan",
    meta: {},
    children: [
      {
        path: "",
        name: "List-Pesanan",
        component: () => import("../views/Pesanan/List-Pesanan.vue"),
        meta: {
          access: ["DEVELOPER"],
        },
      },
      {
        path: "form/:id?",
        name: "Form-Pesanan",
        component: () => import("../views/Pesanan/Form-Pesanan.vue"),
        meta: {
          access: ["DEVELOPER"],
        },
      },
    ],
  },
  {
    path: "/purchase-approval",
    meta: {},
    children: [
      {
        path: "",
        name: "List-Purchase-Approval",
        component: () =>
          import("../views/Purchase-Approval/List-Purchase-Approval.vue"),
        meta: {
          access: ["PURCHASING"],
        },
      },
      {
        path: "form/:id",
        name: "Form-Purchase-Approval",
        component: () =>
          import("../views/Purchase-Approval/Form-Purchase-Approval.vue"),
        meta: {
          access: ["PURCHASING"],
        },
      },
    ],
  },
  {
    path: "/finance-approval",
    meta: {},
    children: [
      {
        path: "",
        name: "List-Finance-Approval",
        component: () =>
          import("../views/Finance-Approval/List-Finance-Approval.vue"),
        meta: {
          access: ["FINANCE"],
        },
      },
      {
        path: "form/:id",
        name: "Form-Finance-Approval",
        component: () =>
          import("../views/Finance-Approval/Form-Finance-Approval.vue"),
        meta: {
          access: ["FINANCE"],
        },
      },
    ],
  },
  {
    path: "/po",
    meta: {},
    children: [
      {
        path: "",
        name: "Purchase Order",
        component: () => import("../views/PO/List-PO.vue"),
        meta: {
          access: ["PURCHASING"],
        },
      },
      {
        path: "detail/:id",
        name: "Detail Order",
        component: () => import("../views/PO/Detail-Order.vue"),
        meta: {
          access: ["PURCHASING"],
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { noAuth: true },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/noaccess",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/NoAccess"),
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      noAuth: true,
    },
    component: () => import("@/views/NotFound"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let checkAccess = (user, access) => {
  if (access.includes("ALL")) {
    return true;
  } else if (
    access.includes("PURCHASING") &&
    parseInt(user.is_purchasing) == 1
  ) {
    return true;
  } else if (access.includes("FINANCE") && parseInt(user.is_finance) == 1) {
    return true;
  } else if (
    access.includes("DEVELOPER") &&
    parseInt(user.is_finance) == 0 &&
    parseInt(user.is_purchasing) == 0
  ) {
    return true;
  }
};

router.beforeEach(async (to, from, next) => {
  let temp_token = null;
  // if (process.env.NODE_ENV == "development") {
  //   // ADMIN
  //   temp_token =
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJMaWdubyBTb2Z0d2FyZSBTb2x1dGlvbiIsImV4cCI6MTY2MDQ0ODk1NSwiZGF0YSI6eyJpZCI6IjEiLCJ1c2VybmFtZSI6ImFkbWluIiwibmFtZSI6IkFkbWluaXN0cmF0b3IiLCJkZXBhcnRtZW50IjoiQURNSU5JU1RSQVRPUiIsImlzX21hbmFnZXIiOiIxIiwiaXNfc3RhZmYiOiIwIiwiYXBwX3NhbGVzX2lzX3N1cGVydmlzZWQiOiIwIn19.ihFAyVuuimxABkvAl6pQGkaVxBfO-tCrfyCzz1Rwfp4";

  //   localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, temp_token);
  // }

  let token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  if (to.meta.noAuth) {
    next();
  } else if (token === null) {
    // console.log("no token");
    next("/login");
  } else {
    try {
      let resp = await axios.get(`${process.env.VUE_APP_BASE_URL}/user_data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      to.meta.user = resp.data;
      if (to.meta?.access == null) {
        next();
      } else {
        let access = checkAccess(resp.data, to.meta.access);
        if (access) {
          next();
        } else {
          next("/noaccess");
        }
      }
    } catch (error) {
      console.log(error);
      console.log("gagal menarik data user");
      localStorage.clear();
      next("/login");
    }
  }
});

export default router;
