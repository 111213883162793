import { useRouter, useRoute } from "vue-router";
import { ref, reactive, inject, watch, onMounted, computed } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import moment from "moment";
moment.locale("id");

export default function useBasic() {
  const router = useRouter();
  const route = useRoute();
  const $http = inject("$http");
  const thousand = inject("thousand");
  const rupiah = inject("rupiah");
  const $q = useQuasar();
  const imgUrl = process.env.VUE_APP_IMG_REF_URL;

  let isLoadingData = ref(false);

  let loadThis = async (fun) => {
    isLoadingData.value = true;
    await fun();
    isLoadingData.value = false;
  };

  let waitThis = async (fun) => {
    $q.loading.show();
    await fun();
    $q.loading.hide();
  };

  let dateModel = (date) => {
    if (date == null || date == "") return "-";
    else if (typeof date == "string") {
      return moment(date).format("DD MMM YYYY");
    } else {
      return `${moment(date.from).format("DD MMM YYYY")} - ${moment(
        date.to
      ).format("DD MMM YYYY")}`;
    }
  };

  return {
    router,
    route,
    $http,
    thousand,
    rupiah,
    $q,
    moment,
    isLoadingData,
    ref,
    reactive,
    watch,
    onMounted,
    computed,
    loadThis,
    waitThis,
    dateModel,
    imgUrl,
  };
}
